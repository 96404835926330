import React from 'react'
import "./OurCapabilities.css";
import capa1 from "../images/Capa1.jpg";
import NALCOON1 from "../images/NALCOON1.jpg";
import concert from "../images/Concert.jpg";
import entertainment from "../images/entertainment.jpg";
import wedding from "../images/Wedding3.jpg";
import meet from "../images/meet.jpg";
import fashion from "../images/fashion.jpg";
import tour from "../images/tour.jpg";
import workshop from "../images/Conference3.jpg";
import product_launch from "../images/product_launch.jpg";

const OurCapabilities = () => {
  return (
    <>
      <div className="CapabilitiesParent">
        <h2 className="headingCls">Our Capabilities</h2>

        {/* Corporate Conferences/Events */}
        <div className="capabilityItem">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Corporate Conferences/Events</h4>
            <p className="contentCls">
              Through understanding a company’s unique brand and identifying their target audience, our team designs an event concept and coordinates all technical aspects before the event begins. We promise you an exquisite and splendid time for your delegates, business associates, and employees.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={capa1} alt="Corporate Conferences" />
          </div>
        </div>

        {/* Medical Conferences/Events */}
        <div className="capabilityItem reversed">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Medical Conferences/Events</h4>
            <p className="contentCls">
              Whether you are planning a medical conference, seminar, or awards dinner, each event is unique and requires a strong foundation to make it a success. With over two decades of experience managing conferences, we are uniquely suited to help you develop strategies to take your conference to the next level.
              <br /><br />
              We also take advantage of the latest tools and technology to streamline the logistics for your conference such as a centralized database for all your participants, transparent financials, historical profiling, and promotional tools.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={NALCOON1} alt="Medical Conferences" />
          </div>
        </div>

        {/* Entertainment */}
        <div className="capabilityItem">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Entertainment</h4>
            <p className="contentCls">
              Sukaryam has experience and an extensive database of talented performers to suit all of your entertainment needs. From celebrity singers and dancers to X-Factor, The Voice, and India’s Got Talent contestants, as well as chart-topping artists, our priority is to find the most unique and exceptional act to suit your event.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={entertainment} alt="Entertainment" />
          </div>
        </div>

        {/* Concerts & Artists Management */}
        <div className="capabilityItem reversed">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Concerts & Artists Management</h4>
            <p className="contentCls">
              Celebrities from different fields prove an asset and spice up any event. In our country, Bollywood or sports celebrities hold a larger-than-life image among their fans, and thus this management is dealt with special care by our professionals.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={concert} alt="Concerts & Artists Management" />
          </div>
        </div>

        {/* Weddings/Social Events Management */}
        <div className="capabilityItem">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Weddings/Social Events Management</h4>
            <p className="contentCls">
              We ensure that we transform your imagination of a dream wedding into a spectacular celebration. We craft an astounding wedding of your choice – extravagant or intimate, royal or traditional. 
              <br /><br />
              Be it Goa, Rishikesh, Jim Corbett, Shimla, Kerala, Udaipur, or any other destination, we have been managing multiple destination weddings in India and abroad within unbelievable budgets. We will make it happen in famous forts, hotels, palaces, and beaches.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={wedding} alt="Weddings/Social Events" />
          </div>
        </div>

        {/* Dealers/Employees/Investors Meet */}
        <div className="capabilityItem reversed">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Dealers/Employees/Investors Meet</h4>
            <p className="contentCls">
              Sometimes, when you are announcing a new distribution initiative or want to motivate your distribution, sales channels, dealers, investors, or shareholders, a spectacular event with the right kind of information, speakers, and entertainment is essential. Our specialized team does everything, from selecting the venue to hospitality and catering for any business meet.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={meet} alt="Dealers Meet" />
          </div>
        </div>

        {/* Product Launches */}
        <div className="capabilityItem">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Product Launches</h4>
            <p className="contentCls">
              At Sukaryam, we understand the importance of first impressions and the need for a high-impact event to introduce your product in style. Our dedicated events team is experienced in all elements of launch delivery, so you can concentrate on unveiling the product and leave the planning, logistics, and management to us.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={product_launch} alt="Product Launch" />
          </div>
        </div>

        {/* MICE | Corporate Tours */}
        <div className="capabilityItem reversed">
          <div className="capabilityText">
            <h4 className="subHeadingCls">MICE | Corporate Tours</h4>
            <p className="contentCls">
              There is a huge extent of customization in MICE (Offsite) trips in terms of destinations, conference activities, hotel arrangements, sightseeing, entertainment, gala dinner venue, team building, and more. Our prime focus is always to cater to our customer’s specific needs and the intent is to delight the customer.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={tour} alt="MICE Corporate Tours" />
          </div>
        </div>

        {/* Workshops */}
        <div className="capabilityItem">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Workshops</h4>
            <p className="contentCls">
              Inspirational talks from passionate, creative people. The idea is simple: those who do things can inspire the rest of us to go and do things too. We help in making all the arrangements.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={workshop} alt="Workshops" />
          </div>
        </div>

        {/* Fashion Shows */}
        <div className="capabilityItem reversed">
          <div className="capabilityText">
            <h4 className="subHeadingCls">Fashion Shows</h4>
            <p className="contentCls">
              The sky’s the limit for fashion when it comes to conceptualization. Our experience in the fashion event industry, along with our network in modeling, sound, and fashion management, has earned us prestigious contracts with renowned companies. We host fashion fairs, exhibitions, and events for various corporates and fashion houses.
            </p>
          </div>
          <div className="capabilityImage">
            <img src={fashion} alt="Fashion Shows" />
          </div>
        </div>

      </div>
    </>
  );
};

export default OurCapabilities;
