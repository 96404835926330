import React from 'react'
import './AboutUs.css';
const AboutUs = () => {
  return (
    <>
    <div className='aboutParent'>
        <h2>About Us</h2>
        <div>Sukaryam is a full-service event management firm. We are strategic event management partners. Be it a corporate conference or a corporate event, a sports event or a social event, we approach every project with meticulous attention to detail and obsessive precision. We understand that a properly executed event can be leveraged to support an organization’s strategic vision, incorporated into a company’s marketing plan, or used to build networks and client loyalty. Regardless of size and scope, we treat your event like a business with clear strategic goals, defined milestones, and a comprehensive plan to ensure that your event is delivered on time and on budget. At Sukaryam, we put your organization first. We learn about your business, we focus on your challenges, and we plan events to support your goals. Any event management company can help you choose linens, organize catering or find a hotel for your event. It takes a real partner to get to know your business, understand your objectives, and design and deliver an event that will help you achieve your goals. At Sukaryam , we believe that each event is unique, with its own objectives and challenges. We also know that in order to deliver a successful event, you need an event management partner that understands this. Our laser sharp focus, unparalleled professionalism and exceptional service means clients can trust that their event is in capable hands.
        </div>
    </div>
    </>
  )
}

export default AboutUs