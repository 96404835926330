import React, { useState } from 'react';
import './Services.css';
import island from "../images/island.jpg";
import hotballoon from "../images/hotballoon.jpg";
import NALCCON from "../images/NALCOON1.jpg";
import flight from "../images/flight.jpg";
import wedding from '../images/Wedding2.jpg';
import travel from '../images/Travel 2.jpg';
import brand from '../images/brand.jpg';

const Services = () => {
  const [selectedService, setSelectedService] = useState('service6');

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li><a style={{color: selectedService == 'service6' ? '#f4dca8' : 'white'}} href="#" onClick={() => setSelectedService('service6')}>Corporate Events/Conferences</a></li>
          <li><a style={{color: selectedService == 'service5' ? '#f4dca8' : 'white'}} href="#" onClick={() => setSelectedService('service5')}>Weddings/ Social Events Management</a></li>
          <li><a style={{color: selectedService == 'service7' ? '#f4dca8' : 'white'}} href="#" onClick={() => setSelectedService('service7')}>Brand Events/Installations</a></li>
          <li><a style={{color: selectedService == 'service1' ? '#f4dca8' : 'white'}} href="#" onClick={() => setSelectedService('service1')}>Explore the World</a></li>
          <li><a style={{color: selectedService == 'service2' ? '#f4dca8' : 'white'}} href="#" onClick={() => setSelectedService('service2')}>Financial Management</a></li>
          <li><a style={{color: selectedService == 'service3' ? '#f4dca8' : 'white'}} href="#" onClick={() => setSelectedService('service3')}>Speaker Management</a></li>
          <li><a style={{color: selectedService == 'service8' ? '#f4dca8' : 'white'}} href="#" onClick={() => setSelectedService('service8')}>Promotion and Registrations</a></li>
          <li><a style={{color: selectedService == 'service4' ? '#f4dca8' : 'white'}} href="#" onClick={() => setSelectedService('service4')}>Team Support</a></li>
        </ul>
      </nav>

      <main className="content">
        {selectedService === 'service1' && (
          <div id="service1" className="service1">
            <div className="imgDiv">
              <div className="myImg">
                <img src={travel} alt="Island" />
              </div>
            </div>
            <div className="textDiv">
              <h2>Travel Management</h2>
              <p className="para">
                Incentive travel is one of the best ways to motivate your team or reward your best assets, your people. We craft, develop and manage travel programmes that will reward, inspire and deliver improved performance for your staff and customers. Be it flight tickets, travel insurances, VISAs, sight-seeing, guided tours, or logistic management, we manage it all.
                <br />
                <br />
                We listen and fully engage with our clients to produce programmes that are unique, money can’t buy experiences. Our highly experienced and passionate team will ensure that your business objectives are met and return on investment is measured.
                <br />
                <br />
                "Twenty years from now you will be more disappointed by the things you didn’t do than by the ones you did do." ~ Mark Twain
                <br />
                <br />
                We tailor-make your holidays! Tell us what you want, and we will design it for you or help you explore with different packages.
              </p>
            </div>
          </div>
        )}

        {selectedService === 'service2' && (
          <div id="service2" className="service1">
            <div className="imgDiv">
              <div className="myImg">
                {/* <img src={flight} alt="Flight" /> */}
              </div>
            </div>
            <div className="textDiv">
              <h2>Financial Management</h2>
              <p className="para">
                We understand the complex financial needs of individuals, families and corporates, and we offer them unique products and services that can meet their requirements. We leverage our resources and wide industry network to craft optimal wealth management and advisory solutions to help our clients achieve their financial goals in a judicious way.
                <br />
                <br />
                Financial planning is all about designing a trip that gets you safely to your destination. The earlier you start acting on your financial planning, the less complicated and the higher the chances of achieving your financial goals.
              </p>
            </div>
          </div>
        )}

        {selectedService === 'service3' && (
          <div id="service3" className="service1">
            <div className="imgDiv">
              <div className="myImg">
                {/* <img src={hotballoon} alt="Hot Air Balloon" /> */}
              </div>
            </div>
            <div className="textDiv">
              <h2>Speaker Management</h2>
              <p className="para">
                Quality speakers and engaging content are keys to drawing attendees to your conference. We invest the time to attract the best possible speakers and manage the logistics of your speaker program management. We handle speaker registration and travel, negotiate and oversee contracts, manage schedules, produce session materials, and perform onsite management support.
                <br />
                <br />
                With the perfect speaker selected, we brief the speaker with your goals and requirements to ensure their speech meets your communication goals.
              </p>
            </div>
          </div>
        )}

        {selectedService === 'service4' && (
          <div id="service4" className="service1">
            <div className="textDiv">
              <h2>Team Support</h2>
              <p className="para">
                Sukaryam provides a unique service for event staffing. We understand client needs, focusing on quality, service, delivery, timeliness, and overcoming manpower challenges. We are available 24/7 to ensure a seamless and successful event.
                <br />
                <br />
                We handle everything behind the scenes, including event crew dress, crew travel, food, grooming & styling, makeup, and more.
              </p>
            </div>
          </div>
        )}

        {selectedService === 'service5' && (
          <div id="service5" className="service1">
            <div className="imgDiv">
              <div className="myImg">
                <img src={wedding} alt="Hot Air Balloon" />
              </div>
            </div>
            <div className="textDiv">
              <h2>Weddings/ Social Events Management</h2>
              <p className="para">
                <br />
                • Weddings
                <br />
                Your Celebration, Your Way!! India’s most reliable event planner “Sukaryam” is an extravagant alternative to the outdated event planning process. A single stop for all events, we provide inspiration, ideas, and choose vendors within your budget.
                <br />
                <br />
                • Social Events
                <br />
                We craft every experience with a personal touch. Every aspect of social events, from logistics to aesthetics, is flawlessly executed. Our years of experience in the field ensure we take your event to the highest standards without exceeding your budget.
              </p>
            </div>
          </div>
        )}

        {selectedService === 'service6' && (
          <div id="service6" className="service1">
            <div className="imgDiv">
              <div className="myImg">
                <img src={NALCCON} alt="NALCCON" />
              </div>
            </div>
            <div className="textDiv">
              <h2>Corporate Events/ Conferences</h2>
              <p className="para">
                • Corporate events are essential in fostering a positive work culture, boosting employee morale, and strengthening team dynamics. Sukaryam enhances the event experience with unique and engaging services in a strategic manner.
                <br />
                <br />
                • Sukaryam is a leading Conference Organizer with expertise in organizing National level conferences. We provide end-to-end solutions for effective conference planning and execution.
                <br />
                <br />
                <h3>Our Recent Work</h3>
                <h4>NALCCON 2024 - AIIMS Rishikesh</h4>
                <p>
                  The National Lung Cancer Conference (NALCCON) 2024 was a significant academic and networking event. Sukaryam provided complete conference planning, logistics, speaker management, and travel solutions for this prestigious conference.
                </p>
              </p>
            </div>
          </div>
        )}

        {selectedService === 'service7' && (
          <div id="service7" className="service1">
            <div className="imgDiv">
              <div className="myImg">
                <img src={brand} alt="Brand Events" />
              </div>
            </div>
            <div className="textDiv">
              <h2>Brand Events/ Installations</h2>
              <p className="para">
                We specialize in styling custom events that resonate with audiences. From product launches to holiday-themed installations, we create visually stunning brand experiences.
              </p>
            </div>
          </div>
        )}

        {selectedService === 'service8' && (
          <div id="service8" className="service1">
            <div className="imgDiv">
              <div className="myImg">
                {/* <img src={flight} alt="Promotion and Registrations" /> */}
              </div>
            </div>
            <div className="textDiv">
              <h2>Promotion and Registrations</h2>
              <p className="para">
                Our marketing experts develop campaigns that turn ideas into reality. We provide a holistic promotion strategy, ensuring that all aspects of your event, from invitations to post-event communications, are covered.
                <br />
                <br />
                Our registration management platform is streamlined for event planners, with easy tools for tracking registrations and engaging with attendees.
              </p>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Services;
