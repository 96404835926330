import React, { useState, useEffect } from 'react';
import './CustomCarousel.css'; // Import custom CSS for styling
import island from "../images/NALCOON1.jpg";
import hotballoon from "../images/hotballoon.jpg";
import flight from "../images/flight.jpg";
import Aboutus1 from "../images/Aboutus1.jpg";
import Aboutus2 from "../images/Aboutus2.jpg";
import Aboutus3 from "../images/Aboutus3.jpg";
import slider1 from "../images/slider1.jpg";
import slider2 from "../images/slider2.jpg";
import slider4 from "../images/Slider4.jpg";
import slider5 from "../images/Slider5.jpg";
import slider6 from "../images/slider6.jpg";

// import CustomCarousel from '../CustomCarousel/CustomCarousel';

const carouselData = [
  {
    imageUrl: Aboutus1,
    label: 'First slide label',
    description: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
  },
  {
    imageUrl: slider2,
    label: 'First slide label',
    description: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
  },
  {
    imageUrl: slider4,
    label: 'First slide label',
    description: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
  },
  {
    imageUrl: slider6,
    label: 'First slide label',
    description: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
  },
  
  {
    imageUrl: Aboutus3,
    label: 'First slide label',
    description: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
  },
  ];

// const ExampleCarouselImage = ({ text }) => {
//   return <div className="carousel-image">{text}</div>;
// };
const ExampleCarouselImage = ({ imageUrl }) => {
    return <img src={imageUrl} alt="" className="carousel-image" />;
  };

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + carouselData.length) % carouselData.length);
  };

  return (
    <div className="carousel-container">
    <div className="carousel-inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
      {carouselData.map((item, index) => (
        <div
          key={index}
          className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
        >
          <ExampleCarouselImage imageUrl={item.imageUrl} />
          <div className="carousel-caption">
            {/* <h3>{item.label}</h3>
            <p>{item.description}</p> */}
          </div>
        </div>
      ))}
    </div>
    <button className="carousel-control-prev" onClick={handlePrev}>
      &#10094;
    </button>
    <button className="carousel-control-next" onClick={handleNext}>
      &#10095;
    </button>
  </div>
  );
};

export default CustomCarousel;
