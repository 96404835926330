// src/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <p>© 2024 Sukaryam . All rights reserved.</p>
    </div>
  );
};

export default Footer;
