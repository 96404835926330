import React from 'react'
import "./Team.css"
import anurag from "../images/Anurag.jpeg";
import bhavna from "../images/default_img.jpg"

const Team = () => {
  return (
    <>
    <div className='TeamParent'>
    {/* <h2 className='heading'>Team heading</h2> */}
    <div className="card">
      <div className='img-div'>
      <img className='img-card' src={anurag}></img>
     <p>
        <b> Anurag Tandon </b>is a product of the some of the most prestigious Indian institutions like The Scindia School, Gwalior and Welcomgroup graduate school of hotel administration, Manipal. He worked with best of international hotel brands like IHG, Carlson hotels, Starwood hotels and resorts and Marriott hotels for over 25 years. Well-travelled all through, he learnt the niceties of event management through his experience with various hotel chains. . He believes in the saying that concepts do not bring transformation, it’s the experience. At Sukaryam he wants to ensure that every client’s event experience is memorable.
        </p>
        </div>

    <span className='Name'>Anurag Tandon</span>
      
       <span className='keyRes'>  Key roles and Key strengths mapped against key delivery</span>
       <p className='repsonsi'>
        Hotel Operations- Conference operations, venue planning, menu planning, seating planning, meal planning, Audio/Video/Light/Sound planning, Décor planning and delivery, Event Flow planning and delivery, registration management

        Sales and Marketing- Selling conferences, Mapping customer’s requirements and Creating solutions, Understanding of different business sector requirements, Pricing finalization, Speakers/hosts and anchors finalization, Artist management, Celebrity management, Branding, Sales and marketing strategies, ATL-BTL activities

        Revenue and E- distribution- Fund raising, Digital strategy, Fund allocation, Social media planning and delivery, website planning and delivery, abstract management, Presentation, Data collation and management, Technology planning, Reporting and post event data analysis

        Travel and accommodation- Venue finalization, Hotel negotiations, Flight ticketing, Logistics management, accommodation management, Food and Beverage management
        </p>

    </div>

{/* second card */}
    <div className="card">
      <div className='img-div'>
      <img className='img-card' src={bhavna}></img>
      <p>
        <b> Bhavna Tandon </b>is veteran from the hotel industry. She spent most of her career managing convention operations and sales working with the best convention brands like Carlson, Grand Hyatt and Metropolitan hotels. After hotels she went on to work with travel brands like Thomas Cook and SOTC where she picked the best of the travel trade and MICE travel planning and operation. In 18 years of her extensive career she picked up the best concepts and hands on knowledge of the trade. Today she adds tremendous value to our operations and concept designing.
        Key roles and Key strengths mapped against key delivery
        Convention sales and operation- Convention planning, décor planning, Flow of events, Venue budget planning, budget negotiations, team building activities, Light and sound inventory management, Gifting, Hosts and anchors, Concepts and themes, PR support, Media planning, Entertainment and Artist management
        Food and Beverage management- Menu selection, Food and beverage planning and delivery
        </p>
        </div>

    <span className='Name'>Bhavna Tandon</span>
      
       <span className='keyRes'>  Key roles and Key strengths mapped against key delivery</span>
       <p className='repsonsi'>Convention sales and operation- Convention planning, décor planning, Flow of events, Venue budget planning, budget negotiations, team building activities, Light and sound inventory management, Gifting, Hosts and anchors, Concepts and themes, PR support, Media planning, Entertainment and Artist management
       Food and Beverage management- Menu selection, Food and beverage planning and delivery
        </p>

    </div>
    <div>
        </div>
    </div>
    </>
  )
}

export default Team