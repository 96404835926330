// import logo from './logo.svg';
// import './App.css';
import { createBrowserRouter,RouterProvider } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import OurCapabilities from "./Pages/OurCapabilities";
import Services from "./Pages/Services";
import Team from "./Pages/Team";
import ContactUs from "./Pages/ContactUs";
import collab from "./Pages/collab";
import Footer from './Footer'; 
import './App.css';

function App() {
  const router = createBrowserRouter([
    {
      path:"/",
      element: <><Navbar/><Home></Home></>
    },
    {
      path:"/AboutUs",
      element: <><Navbar/><AboutUs></AboutUs></>
    },
    {
      path:"/OurCapabilities",
      element:<><Navbar/><OurCapabilities></OurCapabilities></>
    },
    {
      path:"/Services",
      element: <><Navbar/><Services></Services></>
    },
    {
      path:"/Team",
      element: <><Navbar/><Team></Team></>
    },
    {
      path:"/ContactUs",
      element: <><Navbar/><ContactUs></ContactUs></>
    },
])
  return (
    <div className="App">
      {/* <Navbar></Navbar> */}
      <RouterProvider router={router}></RouterProvider>
      <Footer />  {/* Add the Footer at the bottom */}
    </div>
  );
}

export default App;
