import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css';
// import SukaryamLogo from "./im/.SukaryamLogo.jpg"
import SukaryamLogo from "../images/SukaryamLogo.jpg"

const Navbar = () => {
  return (
    <>
    <div className='parentHeader'>
      <div>
          <span>
            <img src={SukaryamLogo} className='logo' title='SukaryamLogo' alt=''></img>
          </span>
      </div>
      <div className='nav-menu'>
              <Link to='/'><li>Home</li></Link>
              
              <Link to='/OurCapabilities'><li>Our Capablities</li></Link>
              <Link to='/Services'><li>Services</li></Link>
              <Link to='/Team'><li>The Team</li></Link>
              <Link to='/ContactUs'><li>Contact Us</li></Link>
        
      </div>
      </div>
    </>
  )
}

export default Navbar