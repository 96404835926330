import React from 'react'
import "./ContactUs.css"

const ContactUs = () => {
  return (
    <>
    <div className='ContactParent'>
    <h2 >Contact Us</h2>
    <div className='address'>
    <br></br>
     Anurag Tandon
     <br></br>
     Contact No. : +91 9717243531
     <br></br>
    </div>
    <h4 className='location'>GURGAON:</h4>
     <div className='address'>
      1st Floor, N-272, Mayﬁeld Gardens, Sector-51, GURGAON, Haryana Pin – 122003
     </div>
     <h4 className='location'>RISHIKESH:</h4>
     <div className='address'>79, Awas Vikas, Virbhadra Road, Uttrakhand Pin – 249201
     
     <br></br>
              Email : sukaryamworld@gmail.com 
              <br></br>
            Website : www.sukaryam.com
       </div>
       </div>
    </>
  )
}

export default ContactUs